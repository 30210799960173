// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_cognito_identity_pool_id": "us-east-2:b891ff2f-9ade-458e-aaad-4a7252059e8b",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_jwb5d2p6o",
    "aws_user_pools_web_client_id": "fdu4drmgpbkh9tiqk520lg0nk",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://cxi2vjcegfe5rkxey3tsa4yhjy.appsync-api.us-east-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cloud_logic_custom": [
        {
            "name": "orderlamda",
            "endpoint": "https://cqygvdv2oh.execute-api.us-east-2.amazonaws.com/agoradev",
            "region": "us-east-2"
        }
    ],
    "aws_user_files_s3_bucket": "amplify-agoraa1bd423ed99645e6b12e85e0f522222bagoradev-agoradev",
    "aws_user_files_s3_bucket_region": "us-east-2"
};


export default awsmobile;
